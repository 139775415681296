/**
 * @version     $Id$
 * @package     PageFly_Affiliate_System
 * @subpackage  Client
 * @author      PageFly Team <admin@pagefly.io>
 * @copyright   Copyright (C) 2019 PageFly.io. All Rights Reserved.
 * @license     GNU/GPL v2 or later http://www.gnu.org/licenses/gpl-2.0.html
 */

export default function loadGA(profile) {
    try {
        // Remove existing GA script if profile changed.
        var d = document
        var s = d.getElementById('gtag-script-' + profile)

        if (s && s.src.indexOf(profile) < 0) {
            s.parentNode.removeChild(s)
            s = null
        }

        // Load GA script for provided profile.
        if (!s) {
            s = d.createElement('script')
            s.id = 'gtag-script-' + profile
            s.src = 'https://www.googletagmanager.com/gtag/js?id=' + profile
            s.async = true

            d.getElementsByTagName('head')[0].appendChild(s)

            window.dataLayer = window.dataLayer || []
            window.gtag = function() {
                window.dataLayer.push(arguments)
            }

            window.gtag('js', new Date())
            window.gtag('config', profile)
        }
    } catch (e) {
        console.warn(e.message)
    }
}
