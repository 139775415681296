/**
 * @version     $Id$
 * @package     PageFly_Affiliate_System
 * @subpackage  Client
 * @author      PageFly Team <admin@pagefly.io>
 * @copyright   Copyright (C) 2019 PageFly.io. All Rights Reserved.
 * @license     GNU/GPL v2 or later http://www.gnu.org/licenses/gpl-2.0.html
 */

import React from 'react'

export default function Content(props) {
	return (
		<main className="pt-5 pb-5" style={{background: '#f5f6f9', minHeight: 'calc(100vh - 60px)'}}>
			<div className="container">
				{props.children}
			</div>
		</main>
	)
}
