/**
 * @version     $Id$
 * @package     PageFly_Affiliate_System
 * @subpackage  Client
 * @author      PageFly Team <admin@pagefly.io>
 * @copyright   Copyright (C) 2019 PageFly.io. All Rights Reserved.
 * @license     GNU/GPL v2 or later http://www.gnu.org/licenses/gpl-2.0.html
 */

try {
	window.$crisp = [];
	window.CRISP_WEBSITE_ID = "7cd1799e-e8eb-476e-8cb7-33778fc41c2a";
	(function(){
		var d = document;
		var s = d.createElement("script");
		s.src = "https://client.crisp.chat/l.js";
		s.async = 1;
		d.getElementsByTagName("head")[0].appendChild(s);
	})();

	window.$crisp.push(["set", "session:segments", [["pagefly-affiliate"]]])

} catch (e) {
	console.warn(e.message)
}