/**
 * @version     $Id$
 * @package     PageFly_Affiliate_System
 * @subpackage  Client
 * @author      PageFly Team <admin@pagefly.io>
 * @copyright   Copyright (C) 2019 PageFly.io. All Rights Reserved.
 * @license     GNU/GPL v2 or later http://www.gnu.org/licenses/gpl-2.0.html
 */

// Define global configuration.
const Config = {
	server_url: 'https://partners.pagefly.io/',
	screens: [
		{
			name: 'dashboard',
			link: '/dashboard',
			text: 'Dashboard'
		},
		{
			name: 'referrals',
			link: '/referrals',
			text: 'Referrals'
		},
		{
			name: 'payouts',
			link: '/payouts',
			text: 'Payouts'
		},
		{
			name: 'resources',
			link: '/resources',
			text: 'Resources'
		}
	],
	default_screen: 'dashboard'
}

export default Config
