/**
 * @version     $Id$
 * @package     PageFly_Shared_Library
 * @author      PageFly Team <admin@pagefly.io>
 * @copyright   Copyright (C) 2019 PageFly.io. All Rights Reserved.
 * @license     GNU/GPL v2 or later http://www.gnu.org/licenses/gpl-2.0.html
 */

// Import assets.
import './table.css'

// Import necessary libraries.
import React from 'react'
import PropTypes from 'prop-types'
import Modal, {StyledBackdrop} from './modal'
import {createPopper} from '@popperjs/core'
import {translate} from '../includes/helpers'

/**
 * React component to render a quick tour.
 */
export default class QuickTour extends React.Component {
	static propTypes = {
		open: PropTypes.bool,
		introTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		introMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		introActions: PropTypes.object,
		destinations: PropTypes.array,
		startFromIndex: PropTypes.number,
		outroTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		outroMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		outroActions: PropTypes.object
	}

	static defaultProps = {
		introTitle: translate('Hello and welcome'),
		introMessage: translate('Do you want to start a quick tour?'),
		introActions: {
			ok: {
				label: translate('Yes, sure!'),
				callback: (event, instance) => console.log('User want to start quick tour.', {event, instance})
			},
			cancel: {
				label: translate('No, thanks!'),
				callback: (event, instance) => console.log('User want to skip quick tour.', {event, instance})
			}
		},
		destinations: [],
		outroTitle: translate("That's all"),
		outroMessage: translate('Do you want to restart the quick tour one more time?'),
		outroActions: {
			ok: {
				label: translate('Yes, sure!'),
				callback: (event, instance) => console.log('User want to restart quick tour.', {event, instance})
			},
			cancel: {
				label: translate('No, thanks!'),
				callback: (event, instance) => console.log('User want to finish quick tour.', {event, instance})
			}
		}
	}

	constructor(props) {
		super(props)

		// Set initial state.
		const {open} = this.props

		this.state = {
			open,
			touring: false
		}
	}

	static getDerivedStateFromProps(props, state) {
		const newState = {}

		if (props.open !== undefined && props.open !== state.open) {
			newState.open = props.open
		}

		if (props.startFromIndex !== undefined && props.startFromIndex !== state.index) {
			newState.index = props.startFromIndex
		}

		if (JSON.stringify(newState) !== '{}') {
			return newState
		}

		return null
	}

	handleOkButton = (event) => {
		const {touring, index} = this.state
		const {introActions, destinations, outroActions} = this.props

		// Get action data.
		let ok

		if (!touring && index === destinations.length) {
			ok = outroActions.ok
		} else {
			ok = introActions.ok
		}

		// Execute callback function if provided.
		if (typeof ok.callback === 'function') {
			if (ok.callback(event, this) === false) {
				return
			}
		}

		// Start quick tour.
		this.tour()
	}

	handleCancelButton = (event) => {
		const {touring, index} = this.state
		const {introActions, destinations, startFromIndex, outroActions} = this.props

		// Get action data.
		let cancel

		if (!touring && index === destinations.length) {
			cancel = outroActions.cancel
		} else {
			cancel = introActions.cancel
		}

		// Execute callback function if provided.
		if (typeof cancel.callback === 'function') {
			if (cancel.callback(event, this) === false) {
				return
			}
		}

		// Hide quick tour.
		this.setState({open: false, index: startFromIndex || 0})
	}

	tour = (index) => {
		// Get destination.
		const {destinations, startFromIndex} = this.props
		index = index === undefined ? (startFromIndex || 0) : index
		const destination = destinations[index]

		// Set new state to the component.
		this.setState({touring: destination ? true : false, index})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {destinations} = this.props
		const {touring, index} = this.state

		// Destroy current popper.
		if (this.popper) {
			this.popper.state.elements.reference.style.zIndex = null
			this.popper.destroy()
		}

		if (touring) {
			const destination = destinations[index]

			if (destination) {
				// Create popper to describe current destination.
				this.refElm = document.querySelector(destination.selector)
				this.refElm.style.zIndex = 9999

				this.popper = createPopper(this.refElm, this.popElm, {
					modifiers: [{ name: 'arrow', options: { element: this.arrElm } }],
					onFirstUpdate: state => this.popElm.classList.add(`bs-popover-${state.placement}`)
				})
			}
		}
	}

	render() {
		const {open, touring, index} = this.state
		const {
			introTitle,
			introMessage,
			introActions,
			destinations,
			outroTitle,
			outroMessage,
			outroActions
		} = this.props

		// Prepare contents.
		let title, message, actions

		if (touring) {
			const destination = destinations[index]

			if (destination) {
				title = destination.title
				message = destination.description
			}
		} else if (index === destinations.length) {
			title = outroTitle
			message = outroMessage
			actions = outroActions
		} else {
			title = introTitle
			message = introMessage
			actions = introActions
		}

		return touring ? (
			<StyledBackdrop>
				<div ref={el => this.popElm = el} className="popover fade show quick-tour-destination">
					<div ref={el => this.arrElm = el} className="arrow"/>
					{title && (
						<h3 className="popover-header quick-tour-destination-title">{title}</h3>
					)}
					<div className="popover-body">
						<div className="mb-2 quick-tour-destination-intro">
							{message}
						</div>
						<div className="d-flex align-items-center justify-content-between quick-tour-destination-nav">
							<button
								type="button"
								className="btn btn-secondary"
								style={index === 0 ? {visibility: 'hidden', pointerEvents: 'none'} : {}}
								onClick={() => this.tour(index - 1)}
							>
								{translate('Back')}
							</button>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => this.tour(index + 1)}
							>
								{translate('Next')}
							</button>
						</div>
					</div>
				</div>
			</StyledBackdrop>
		) : open ? (
			<Modal
				open={true}
				title={title}
				content={message}
				showCloseButton={false}
				buttons={{
					ok: actions.ok ? {
						label: actions.ok.label,
						callback: this.handleOkButton
					} : false,
					cancel: actions.cancel ? {
						label: actions.cancel.label,
						callback: this.handleCancelButton
					} : false
				}}
			/>
		) : null
	}
}
