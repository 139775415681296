/**
 * @version     $Id$
 * @package     PageFly_Affiliate_System
 * @subpackage  Client
 * @author      PageFly Team <admin@pagefly.io>
 * @copyright   Copyright (C) 2019 PageFly.io. All Rights Reserved.
 * @license     GNU/GPL v2 or later http://www.gnu.org/licenses/gpl-2.0.html
 */

import React from 'react'
import Config from '../config'
import {redirect} from '../shared/includes/helpers'

/**
 * Helper function to get profile of logged in user.
 *
 * @param   boolean  force  Whether to force requesting user profile from server?
 *
 * @return  mixed
 */
export const getProfile = async function (force) {
	let lastUser

	// Get data stored in local storage first.
	if (!force) {
		lastUser = localStorage.getItem('pf_aff_sys_usr')

		if (lastUser) {
			lastUser = JSON.parse(lastUser)

			if (lastUser.profile && lastUser.lastAccess + 15 * 60 * 1000 > Date.now()) {
				return lastUser.profile
			}
		}
	}

	// Request user profile from server.
	const res = await fetch(`${Config.server_url}user/get`)

	if (res.redirected) {
		return redirect(res.url)
	}

	lastUser = {
		profile: await res.json(),
		lastAccess: Date.now()
	}

	localStorage.setItem('pf_aff_sys_usr', JSON.stringify(lastUser))

	return lastUser.profile
}

/**
 * Helper function to clear profile of logged in user stored in the local storage.
 *
 * @return  mixed
 */
export const clearProfile = async function () {
	if (localStorage.getItem('pf_aff_sys_usr')) {
		localStorage.removeItem('pf_aff_sys_usr')
	}
}

// Create a context for storing profile of current logged in user.
export const UserContext = React.createContext({
	user: {},
	refresh: () => getProfile(true)
})
