/**
 * @version     $Id$
 * @package     PageFly_Shared_Library
 * @author      PageFly Team <admin@pagefly.io>
 * @copyright   Copyright (C) 2019 PageFly.io. All Rights Reserved.
 * @license     GNU/GPL v2 or later http://www.gnu.org/licenses/gpl-2.0.html
 */

// Import assets.
import './navbar.css'

// Import necessary libraries.
import React from 'react'
import PropTypes from 'prop-types'
import Link from './link'
import Menu from './menu'
import {generateId, translate} from '../includes/helpers'

/**
 * React component to render a navigation bar.
 */
export default class NavBar extends React.Component {
	static propTypes = {
		id: PropTypes.string,
		root: PropTypes.string,
		brand: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
		left: PropTypes.array,
		right: PropTypes.array,
		active: PropTypes.string
	}

	static defaultProps = {
		id: null,
		root: '/',
		brand: null,
		left: [],
		right: [],
		active: null
	}

	render() {
		this.id = this.id || this.props.id || `navbar-${generateId()}`

		return (
			<nav className="navbar navbar-expand-lg navbar-light bg-light">
				{this.props.brand ? [
					<Link key="brand" className="navbar-brand d-flex align-items-center" href={this.props.root}>
						{this.props.brand}
					</Link>,
					<button
						key="toggler"
						className="navbar-toggler"
						type="button"
						data-toggle="collapse"
						data-target={`#${this.id}`}
						aria-controls={this.id}
						aria-expanded="false"
						aria-label={translate('Toggle navigation')}
					>
						<span className="navbar-toggler-icon"></span>
					</button>
				] : null}
				<div id={this.id} className="collapse navbar-collapse">
					{this.props.left.length ? (
						<ul className="navbar-nav mr-auto">
							{this.renderItems('left')}
						</ul>
					) : null}
					{this.props.right.length ? (
						<ul className="navbar-nav ml-auto">
							{this.renderItems('right')}
						</ul>
					) : null}
				</div>
			</nav>
		)
	}

	renderItems(position) {
		const items = this.props[position]

		return items.map(item => {
			// Prepare item class.
			const classes = ['nav-item']

			if (item.dropdown) {
				classes.push('dropdown')
			}

			if (this.props.active === item.name) {
				classes.push('active')
			}

			return (
				<li id={item.name} key={item.name} className={classes.join(' ')}>
					{item.link ? (
						<Link
							className={'nav-link' + (item.dropdown ? ' dropdown-toggle' : '')}
							href={item.link}
							onClick={item.onClick}
						>
							{translate(item.text)}
						</Link>
					) : (
						<button
							className={'btn btn-link nav-link' + (item.dropdown ? ' dropdown-toggle' : '')}
							onClick={item.onClick}
						>
							{translate(item.text)}
						</button>
					)}
					{item.dropdown ? (
						<Menu
							show={false}
							items={item.dropdown}
							className={position === 'right' ? 'dropdown-menu-right' : null}
						/>
					) : null}
				</li>
			)
		})
	}
}
