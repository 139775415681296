/**
 * @version     $Id$
 * @package     PageFly_Affiliate_System
 * @subpackage  Client
 * @author      PageFly Team <admin@pagefly.io>
 * @copyright   Copyright (C) 2019 PageFly.io. All Rights Reserved.
 * @license     GNU/GPL v2 or later http://www.gnu.org/licenses/gpl-2.0.html
 */

// Import assets.
import '../templates/crisp'
import logo from '../assets/images/logo-white.svg'

// Import necessary libraries.
import React from 'react'
import Config from '../config'
import styled from 'styled-components'
import Modal from '../shared/components/modal'
import NavBar from '../shared/components/navbar'
import QuickTour from '../shared/components/quick-tour'
import {triggerEvent} from '../shared/includes/event'
import {getProfile, UserContext} from '../bootstrap/user'
import {translate, request} from '../shared/includes/helpers'

export default class Header extends React.Component {
	static defaultProps = {
		quickTourDestinations: [
			{
				selector: '#referrals',
				description: translate('This is the Referrals page. You can find information about your referrals and your affiliate link on this page.')
			},
			{
				selector: '#payouts',
				description: translate('The Payouts page will show you the payouts and commissions, including the billing date, payment date and the amount of payout.')
			},
			{
				selector: '#resources',
				description: translate('The resources page provides useful learning resources and guides , which should help you get more commission, including SEO Optimization, Sale Kits and Marketing Resources.')
			}
		]
	}

	constructor(props) {
		super(props)

		this.state = {
			user: null,
			showQuickTour: false,
			skippedQuickTour: false
		}
	}

	async componentDidMount() {
		// Get current user.
		const user = await getProfile()

		if (user) {
			this.setState({user, showQuickTour: !user.activity || !user.activity.seenQuickTour})
		}

		// Listen to event to show quick tour.
		document.addEventListener('showQuickTour', this.showTour)
	}

	componentWillUnmount() {
		// Stop listening to event for displaying quick tour.
		document.removeEventListener('showQuickTour', this.showTour)
	}

	showTour = () => {
		this.setState({showQuickTour: true})
	}

	render() {
		const {quickTourDestinations, screen} = this.props
		const {user, showQuickTour, skippedQuickTour} = this.state

		return <>
			<StyledHeader>
				<div className="container">
					<UserContext.Consumer>
						{({user}) => {
							// Prepare user menu.
							const right = [{
								name: 'quick-tour',
								text: <i className="fa fa-question-circle"/>,
								onClick: () => {
									triggerEvent(document, 'showQuickTour', {user})
								}
							}]

							if (user.email) {
								right.push({
									name: 'account',
									text: user.firstName,
									dropdown: [
										{
											name: 'profile',
											link: '/profile',
											text: translate('My Account')
										},
										{
											name: 'logout',
											link: '/logout',
											text: translate('Logout')
										},
									]
								})
							}

							return (
								<NavBar
									root={Config.server_url}
									brand={<>
										<img src={logo} width="250" height="33" alt={translate('PageFly Partner')}/>
										{user.affiliateLevel > 1 && (
											<sup className="pl-1 text-uppercase">{translate('Level 2')}</sup>
										)}
									</>}
									{...(user.email ? {left: Config.screens, right, active: screen} : {})}
								/>
							)
						}}
					</UserContext.Consumer>
				</div>
			</StyledHeader>

			{user && <>
				<QuickTour
					open={showQuickTour}
					introTitle={translate('Hello and welcome to PageFly Affiliate Program')}
					introMessage={<>
						<p>{translate('This tour will guide you through the Main menu of PageFly Affiliate Partner Dashboard.')}</p>
						<p>{translate('Do you want to start it?')}</p>
					</>}
					introActions={{
						ok: {
							label: translate('Start Tour'),
							callback: () => this.markUserSeenQuickTour()
						},
						cancel: {
							label: translate('Skip'),
							callback: () => {
								this.markUserSeenQuickTour()
								this.setState({showQuickTour: false, skippedQuickTour: true})
							}
						}
					}}
					destinations={quickTourDestinations}
					outroTitle={''}
					outroMessage={<>
						<p>{translate('This is the end of the tour. If you have unclear concern or need more information about our affiliate program, you can contact us via 24/7 Live Chat or Email: affiliate@pagefly.io')}</p>
						<p>{translate('To start this tour again, please click on the icon <i className="fa fa-question-circle"></i> on header bar.')}</p>
					</>}
					outroActions={{
						ok: false,
						cancel: {
							label: translate('End Tour'),
							callback: () => this.setState({showQuickTour: false, skippedQuickTour: false})
						}
					}}
				/>
				<Modal
					open={skippedQuickTour}
					content={<>
						{'To start this tour again, please click on the icon'}
						<i className="fa fa-question-circle mx-2" />
						{'in the header bar.'}
					</>}
					buttons={{
						ok: false,
						cancel: {
							label: translate('Close'),
							callback: () => this.setState({skippedQuickTour: false})
						}
					}}
				/>
			</>}
		</>
	}

	markUserSeenQuickTour = () => {
		const {server} = this.props

		request(
			`${server}user/save`,
			{activity: {seenQuickTour: true}},
			'POST',
			async () => {
				// Update user profile stored in local storage. Otherwise, users
				// will still see the quick tour popped up everytime they visit
				// the dashboard until they log out and log in again.
				const user = await getProfile(true)

				this.setState({user})
			},
			true
		)
	}
}

const StyledHeader = styled.div`
	background: #292e40;
	.navbar {
		background: transparent !important;
		padding: 0;
		a, .btn {
			color: #c0c6d2 !important;
			font-size: 13px;
			font-weight: 500;
		}
		.active a {
			color: #5851be !important;
		}
	}
	#account {
		.dropdown-toggle::after {
			border-top-color: #c0c6d2
		}
		.dropdown-menu {
			border-color: rgba(0,0,0,.05);
			box-shadow: 0 0 8px 2px rgba(45,56,132,0.04);
			border-radius: 0 0 0.25rem 0.25rem;
			margin: 0;
			top: 140%;
			transition: all .3s;
			opacity: 0;
			display: initial;
			pointer-events: none;
			a {
				color: #292e40 !important;
			}
		}
		&:hover .dropdown-menu {
			opacity: 1;
			top: 100%;
			pointer-events: initial;
		}
	}
	#account, #quick-tour {
		padding: 0.5rem 0;
	}
	.email {
		position: fixed;
		left: 20px;
		bottom: 20px;
	}
	@media (max-width: 991px) {
		.navbar-collapse.collapse.show #account .dropdown-menu {
			display: block;
			opacity: 1 !important;
		}
	}
`
