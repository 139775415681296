/**
 * @version     $Id$
 * @package     PageFly_Affiliate_System
 * @subpackage  Client
 * @author      PageFly Team <admin@pagefly.io>
 * @copyright   Copyright (C) 2019 PageFly.io. All Rights Reserved.
 * @license     GNU/GPL v2 or later http://www.gnu.org/licenses/gpl-2.0.html
 */

import Config from '../config'

try {
	(function() {
		var d = document
		var s = d.getElementById('pf-affiliate-script')

		if (!s) {
			s = d.createElement('script')
			s.id = 'pf-affiliate-script'
			s.src = `${Config.server_url}init.js`
			s.async = true
			s.defer = true

			d.getElementsByTagName('head')[0].appendChild(s)
		}
	})()
} catch (e) {
	console.warn(e.message)
}
