/**
 * @version     $Id$
 * @package     PageFly_Shared_Library
 * @author      PageFly Team <admin@pagefly.io>
 * @copyright   Copyright (C) 2019 PageFly.io. All Rights Reserved.
 * @license     GNU/GPL v2 or later http://www.gnu.org/licenses/gpl-2.0.html
 */

// Import necessary libraries.
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {toTitleCase, translate} from '../includes/helpers'

/**
 * React component to render a modal.
 */
export default class Modal extends React.Component {
	static propTypes = {
		open: PropTypes.bool,
		width: PropTypes.string,
		trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
		title: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
		content: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
		buttons: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
		onOpen: PropTypes.func,
		onClose: PropTypes.func,
		processing: PropTypes.bool,
		progressBar: PropTypes.bool,
		showCloseButton: PropTypes.bool
	}

	static defaultProps = {
		trigger: null,
		title: null,
		content: null,
		buttons: {},
		onOpen: null,
		onClose: null,
		progressBar: false,
		showCloseButton: true
	}

	constructor(props) {
		super(props)

		this.state = {
			open: this.props.open,
			processing: this.props.processing
		}
	}

	static getDerivedStateFromProps(props, state) {
		const newState = {}

		if (props.open !== undefined && props.open !== state.open) {
			newState.open = props.open
		}

		if (props.processing !== undefined && props.processing !== state.processing) {
			newState.processing = props.processing
		}

		if (JSON.stringify(newState) !== '{}') {
			return newState
		}

		return null
	}

	handleKeyUp = e => {
		e.keyCode === 27 && this.closeModal()
	}

	openModal = () => {
		this.setState({open: true})
		this.props.onOpen && this.props.onOpen()
		document.addEventListener('keyup', this.handleKeyUp)
	}

	closeModal = () => {
		this.setState({open: false})
		this.props.onClose && this.props.onClose()
		document.removeEventListener('keyup', this.handleKeyUp)
	}

	handleButtonClick = e => {
		const {processing} = this.state

		if (!processing) {
			let target = e.target

			while (target && target.nodeName !== 'BUTTON' && target.nodeName !== 'BODY') {
				target = target.parentNode
			}

			if (target.nodeName === 'BUTTON') {
				const {buttons} = this.props
				const type = target.classList.contains('btn-primary') ? 'ok' : 'cancel'
				const cb = buttons[type] ? (buttons[type].callback || buttons[type]) : null

				if (typeof cb === 'function') {
					e.persist()

					if (cb(e, this) === false) {
						return
					}
				}
			}

			this.closeModal()
		}
	}

	getButtonLabel(type) {
		const {buttons} = this.props

		if (typeof buttons[type] === 'object' && buttons[type].label) {
			return buttons[type].label
		}

		if (typeof buttons[type] === 'string') {
			return buttons[type]
		}

		return translate(toTitleCase(type))
	}

	toggleProcessingState() {
		const {processing} = this.state

		this.setState({processing: !processing})
	}

	setProcessingState(percent) {
		if (this.progressBar) {
			const progress = this.progressBar.firstElementChild

			progress.textContent = `${percent}%`
			progress.style.width = `${percent}%`
			progress.setAttribute('aria-valuenow', percent)
		}
	}

	render() {
		const {open, processing} = this.state
		const {trigger, width, title, content, buttons, children, progressBar, showCloseButton} = this.props

		return <>
			{trigger && (
				<div ref={el => this.trigger = el} className="d-inline" onClick={this.openModal}>
					{typeof trigger === 'function' ? trigger(this) : trigger}
				</div>
			)}
			{open && (
				<StyledBackdrop
					ref={el => this.modal = el}
					className="modal fade show"
					onClick={e => !processing && e.target === this.modal && this.closeModal()}
				>
					<div className="modal-dialog" style={width ? {
						width,
						maxWidth: 'initial'
					} : null}>
						<div className="modal-content">
							{title && (
								<div ref={el => this.header = el} className="modal-header">
									<h5 className="modal-title">
										{typeof title === 'function' ? title(this) : title}
									</h5>
									{showCloseButton && <button
										type="button"
										className="close"
										disabled={processing}
										onClick={() => !processing && this.closeModal()}
									>
										<span aria-hidden="true">&times;</span>
									</button>}
								</div>
							)}
							{content && (
								<div ref={el => this.body = el} className="modal-body">{
									(processing && progressBar) ? (
										<div
											ref={el => this.progressBar = el}
											className="progress progress-bar-striped progress-bar-animated"
										>
											<div
												className="progress-bar"
												role="progressbar"
												style={{width: '0%'}}
												aria-valuenow="0"
												aria-valuemin="0"
												aria-valuemax="100"
											>
												0%
											</div>
										</div>
									) : (typeof content === 'function' ? content(this) : content)
								}</div>
							)}
							{buttons && (
								<div ref={el => this.footer = el} className="modal-footer">
									{typeof buttons === 'function' ? buttons(this) : <>
										{buttons.cancel !== false && (
											<button
												type="button"
												className="btn btn-secondary"
												disabled={processing}
												onClick={this.handleButtonClick}
											>
												{this.getButtonLabel('cancel')}
											</button>
										)}
										{buttons.ok !== false && (
											<button
												type="button"
												className="btn btn-primary"
												disabled={processing}
												onClick={this.handleButtonClick}
											>
												{
													processing
														? <i className="fa fa-spin fa-spinner"/>
														: this.getButtonLabel('ok')
												}
											</button>
										)}
									</>}
								</div>
							)}
							{children && (typeof children === 'function' ? children(this) : children)}
						</div>
					</div>
				</StyledBackdrop>
			)}
		</>
	}
}

export const StyledBackdrop = styled.div`
	display: block;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 9998;
	background: rgba(0, 0, 0, .33);
`
