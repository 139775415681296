/**
 * @version     $Id$
 * @package     PageFly_Shared_Library
 * @author      PageFly Team <admin@pagefly.io>
 * @copyright   Copyright (C) 2019 PageFly.io. All Rights Reserved.
 * @license     GNU/GPL v2 or later http://www.gnu.org/licenses/gpl-2.0.html
 */

// Import necessary libraries.
import React from 'react'
import Router from './router'

// Define text mapping object.
const textMapping = {}

/**
 * Helper function to translate a text string.
 *
 * @param   string  text
 * @param   object  mapping
 *
 * @return  string
 */
export function translate(text, mapping) {
	// Store text mapping if provided.
	if (mapping) {
		for (let p in mapping) {
			textMapping[p] = mapping[p]
		}
	}

	// Get mapped value for the specified text.
	let str = textMapping[text] || text

	// If the mapped value contains HTML markups, parse it.
	if (typeof str === 'string' && str.match(/(<\/?[a-zA-Z0-9\-_]+[^>]*\/?>|&#?[a-z0-9]+;)/)) {
		str = parseHTML(str)
	}

	return str
}

/**
 * Parse text string containing HTML tags to HTML elements.
 *
 * @param   string  str  The text string to parse.
 *
 * @return  array
 */
export function parseHTML(str) {
	var elm = document.createElement('div')
	elm.innerHTML = str.replace(/\s(class|for)Name=/g, ' $1=')

	return (function convert(elms) {
		var elm = []

		for (var i = 0; i < elms.length; i++) {
			if (elms[i].nodeType === 1) {
				var attrs = {}

				for (var j = 0; j < elms[i].attributes.length; j++) {
					if (['class', 'for'].indexOf(elms[i].attributes[j].name) < 0) {
						if (elms[i].attributes[j].name === 'style') {
							var props = {}
							var style = elms[i].attributes[j].value.split(';')

							for (var k = 0; k < style.length; k++) {
								if (style[k].trim() !== '') {
									style[k] = style[k].split(':')
									props[style[k][0].trim()] = style[k][1].trim()
								}
							}

							attrs[elms[i].attributes[j].name] = props
						} else {
							attrs[elms[i].attributes[j].name] = elms[i].attributes[j].value
						}
					} else {
						attrs[elms[i].attributes[j].name + 'Name'] = elms[i].attributes[j].value
					}
				}

				if (elms[i].childNodes.length) {
					elm.push(
						React.createElement(elms[i].nodeName, attrs, convert(elms[i].childNodes))
					)
				} else {
					elm.push(React.createElement(elms[i].nodeName, attrs))
				}
			} else if (elms[i].nodeType === 3) {
				elm.push(elms[i].nodeValue)
			}
		}

		return elm
	})(elm.childNodes)
}

/**
 * Helper function to generate an unique string.
 *
 * @param   integer  length
 *
 * @return  string
 */
export function generateId(length) {
	// Prepare length.
	length = length || 8

	// Define list of supported characters.
	const charList = 'abcdefghjkmnpqrstuvwxyzABCDEFGHJKMNPQRSTUVWXYZ0123456789'

	// Generate an unique string.
	let str = ''

	for (let i = 0; i < length; i++) {
		str += charList[Math.floor(Math.random() * (i === 0 ? 46 : 56))]
	}

	return str
}

/**
 * Helper function to convert the first letter of a string to uppercase.
 *
 * @param   string   str
 * @param   boolean  alphanum  Whether to remove all non-alphanumeric characters.
 *
 * @return  string
 */
export function toTitleCase(str, alphanum) {
	if (!str || typeof str != 'string') {
		return str
	}

	// Replace unsupported characters with space.
	if (alphanum) {
		str = str.replace(/[^a-zA-Z0-9]+/g, ' ')
	} else {
		// eslint-disable-next-line
		str = str.replace(/[^a-zA-Z0-9\/\-@._]+/g, ' ')
	}

	// Add a space before every uppercase character.
	str = str.replace(/([^\s])([A-Z])/g, '$1 $2')

	return str.replace(/\w\S*/g, function (word) {
		return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
	})
}

/**
 * Helper function to convert a string to camel case format.
 *
 * @param   string   str
 * @param   boolean  ucfirst  Whether to make the first letter uppercase.
 *
 * @return  string
 */
export function toCamelCase(str, ucfirst) {
	if (!str || typeof str != 'string') {
		return str
	}

	// Keep only alphanumeric, space, dash, dot and underscore characters.
	str = str.replace(/[^a-zA-Z0-9\s\-._]+/g, '')

	return str.replace(/(?:\s|[A-Z]|\b\w)/g, function (letter, index) {
		return ((ucfirst && index === 0) || index > 0) ? letter.toUpperCase() : letter.toLowerCase()
	}).replace(/[\s\-._]+/g, '')
}

/**
 * Helper function to return a formatted string of Date object.
 *
 * @param   mixed    datetime
 * @param   boolean  includeTime  Whether to include time in the returned string?
 * @param   boolean  utc          Whether to use UTC time or local time?
 *
 * @return  string
 */
export function formatDateTime(datetime, includeTime = false, utc = true) {
	if (!datetime) {
		return '-'
	}

	// Prepare datetime.
	const date = typeof datetime == 'string' ? new Date(datetime) : datetime

	// Format date: dd/mm/yyyy
	const d = utc ? date.getUTCDate() : date.getDate()
	const m = utc ? date.getUTCMonth() : date.getMonth()
	const y = utc ? date.getUTCFullYear() : date.getFullYear()
	let str = `${d < 10 ? '0' : ''}${d}/${m < 9 ? '0' : ''}${m + 1}/${y}`

	// Format time: hh:mm:ss
	if (includeTime) {
		const h = utc ? date.getUTCHours() : date.getHours()
		const m = utc ? date.getUTCMinutes() : date.getMinutes()
		const s = utc ? date.getUTCSeconds() : date.getSeconds()
		str += ` ${h < 10 ? '0' : ''}${h}:${m < 10 ? '0' : ''}${m}:${s < 10 ? '0' : ''}${s}`
	}

	return str
}

/**
 * Helper function to return day name from provided date string.
 *
 * @param   mixed  date
 *
 * @return  string
 */
export function getDayName(date) {
	if (typeof date == 'string') {
		date = new Date(date)
	}

	return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][date.getDay()]
}

/**
 * Helper function to return week name from provided date string.
 *
 * @param   mixed  date
 *
 * @return  string
 */
export function getWeekName(date) {
	if (typeof date == 'string') {
		if (date.match(/^\d\d\d\d-W\d\d$/)) {
			const week = date.split('-')[1]

			if (week === 'W01') {
				return 'W01, ' + date.split('-')[0]
			}

			return week
		} else {
			date = new Date(date)
		}
	}

	// Set time to beginning of the day.
	date.setHours(0, 0, 0, 0)

	// Thursday in current week decides the year.
	date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7)

	// January 4 is always in week 1.
	const week1 = new Date(date.getFullYear(), 0, 4)

	// Adjust to Thursday in week 1 and count number of weeks from date to week1.
	const week = 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
		- 3 + (week1.getDay() + 6) % 7) / 7)

	// Get the four-digit year corresponding to the ISO week of the date.
	date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7)

	if (week === 1) {
		return 'W01, ' + date.getFullYear()
	}

	return 'W' + (week < 10 ? '0' : '') + week
}

/**
 * Helper function to return month name from provided date string.
 *
 * @param   mixed  date
 *
 * @return  string
 */
export function getMonthName(date) {
	if (typeof date == 'string') {
		date = new Date(date)
	}

	if (date.getMonth() === 0) {
		return 'Jan, ' + date.getFullYear()
	}

	return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][date.getMonth()]
}

/**
 * Helper function to return quarter name from provided date string.
 *
 * @param   mixed  date
 *
 * @return  string
 */
export function getQuarterName(date) {
	if (typeof date == 'string') {
		if (date.match(/^\d\d\d\d-Q\d$/)) {
			const quarter = date.split('-')[1]

			if (quarter === 'Q1') {
				return 'Q1, ' + date.split('-')[0]
			}

			return quarter
		} else {
			date = new Date(date)
		}
	}

	// Parse date.
	const parts = date.toISOString().split(/[ T]/)[0].split('-').map(p => parseInt(p))

	if (1 <= parts[1] && parts[1] <= 3) {
		return 'Q1, ' + date.getFullYear()
	}

	if (4 <= parts[1] && parts[1] <= 6) {
		return 'Q2'
	}

	if (7 <= parts[1] && parts[1] <= 9) {
		return 'Q3'
	}

	if (10 <= parts[1] && parts[1] <= 12) {
		return 'Q4'
	}
}

/**
 * Helper method to get the height of browser's viewport.
 *
 * @param   object  win  A window object.
 *
 * @return  number
 */
export function getWinHeight(win) {
	win = win || window

	return win.innerHeight || win.document.documentElement.clientHeight || win.document.body.clientHeight
}

/**
 * Helper method to get the height of a document.
 *
 * @param   object  doc  A document object. E.g. window.document
 *
 * @return  number
 */
export function getDocHeight(doc) {
	doc = doc || document

	// stackoverflow.com/questions/1145850/
	const body = doc.body
	const html = doc.documentElement
	const height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)

	return height
}

/**
 * Helper method to get the current scroll top.
 *
 * @param   object  win  A window object.
 *
 * @return  number
 */
export function getScrollTop(win) {
	win = win || window

	if(typeof win.pageYOffset != 'undefined') {
		// Most browsers except IE before #9
		return win.pageYOffset
	} else {
		const b = win.document.body // IE 'quirks'
		const d = win.document.documentElement // IE with doctype

		return (d.clientHeight ? d : b).scrollTop
	}
}

/**
 * Helper method to get the top offset of an element.
 *
 * @param   object  elm  Element to get top offset for.
 *
 * @return  number
 */
export function getElementTop(elm) {
	let top = elm.offsetTop

	while (elm.offsetParent) {
		elm = elm.offsetParent
		top += elm.offsetTop

		if (elm.scrollHeight > elm.clientHeight) {
			break
		}
	}

	return top
}

/**
 * Helper function to convert an object to query string.
 *
 * @param   object  params     Object to create query string from.
 * @param   string  namespace  A namespace to group query parameters.
 *
 * @return  string
 */
export function toQueryString(params, namespace) {
	return Object.keys(params).map(name => {
		if (params[name] instanceof Array) {
			if (params[name].length === 1) {
				return `${namespace ? `${namespace}[${name}]` : name}=${encodeURIComponent(params[name][0])}`
			}

			return params[name].map((value, idx) => {
				return `${namespace ? `${namespace}[${name}][${idx}]` : `${name}[${idx}]`}=${encodeURIComponent(value)}`
			}).join('&')
		} else if (typeof params[name] === 'object') {
			return toQueryString(params[name], name)
		} else {
			return `${namespace ? `${namespace}[${name}]` : name}=${encodeURIComponent(params[name])}`
		}
	}).join('&')
}

/**
 * Helper function to convert filters object to query string.
 *
 * @param   object  filters  Filter object.
 *
 * @return  string
 */
export function toFiltersQuery(filters) {
	const query = []

	// Define function to refine value.
	const refineValue = (value) => {
		if (typeof value === 'string') {
			if (value.match(/^\d+$/)) {
				value = parseInt(value)
			} else if (value.match(/^\d+\.\d+$/)) {
				value = parseFloat(value)
			}
		}

		return value
	}

	for (let p in filters) {
		let value = filters[p]

		if (value instanceof Array) {
			value = value.map(v => refineValue(v))
		} else if (typeof value === 'object') {
			Object.keys(value).forEach(k => value[k] = refineValue(value[k]))
		}

		query.push(
			`filter_${p}=${encodeURIComponent(typeof value === 'object' ? JSON.stringify(value) : value)}`
		)
	}

	return query.join('&')
}

/**
 * Helper function to redirect to another screen.
 *
 * @param   string  link       Link to redirect to.
 * @param   string  setReturn  Whether to set return URL.
 *
 * @return  void
 */
export function redirect(link, setReturn = true) {
	if (link.indexOf('/login') > -1 && setReturn && link.indexOf('redirect=') < 0) {
		if (!window.location.href.match(/\/(login|forgot)/)) {
			link += (link.indexOf('?') > -1 ? '&' : '?') + 'redirect=' + encodeURIComponent(window.location.href)
		}
	}

	Router.route(link)
}

/**
 * Helper function to send a remote request.
 *
 * @param   string    link  Request URI.
 * @param   object    data  Request body.
 * @param   string    type  Request method.
 * @param   function  cb    Callback function.
 * @param   boolean   json  Whether to post data in JSON format?
 *
 * @return  Promise
 */
export function request(link, data, type, cb, json) {
	return new Promise((resolve, reject) => {
		// Define method to finalize task.
		const finalize = (res) => {
			if (res.code && res.message) {
				res = new Error(translate(res.message))
			}

			if (typeof cb === 'function') {
				cb(res)
			} else if (res instanceof Error) {
				reject(res)
			} else {
				resolve(res)
			}
		}

		// Preset fetch options.
		let options = {
			method: type || 'GET'
		}

		// Prepare request params.
		if (data) {
			if (type && type.toUpperCase() === 'GET') {
				link += (link.indexOf('?') > -1 ? '&' : '?') + toQueryString(data)
			} else {
				let formData

				if (json) {
					formData = JSON.stringify(data)
					options.headers = {
						'Content-Type': 'application/json;charset=utf-8'
					}
				} else {
					formData = new FormData()

					for (let p in data) {
						if (data.hasOwnProperty(p)) {
							formData.append(p, data[p])
						}
					}
				}

				options.body = formData
			}
		}

		fetch(link, options)
			.then(res => {
				if (res.redirected) {
					redirect(res.url)
					throw new Error('redirect')
				}

				return res.json()
			})
			.then(finalize)
			.catch(finalize)
	})
}

/**
 * Helper function to get data and update to current screen.
 *
 * @param   string    link    Link to get data.
 * @param   string    key     Required data key.
 * @param   object    screen  React component of the current screen.
 * @param   function  cb      Callback function.
 *
 * @return  Promise
 */
export function fetchData(link, key, screen, cb) {
	return new Promise((resolve, reject) => {
		const flag = `fetching-${key || link}`

		if (!screen || !screen[flag]) {
			// Define method to finalize task.
			const finalize = (res) => {
				// Check if there is any error occurred at server-side.
				if (res.code && res.message) {
					res = new Error(translate(res.message))
				}

				// Check if required data key is available in response.
				else if (key && res[key] === undefined) {
					res = new Error(translate('Missing data key `%s`.').replace('%s', key))
				}

				// Simply return if redirected.
				if (res instanceof Error && res.message === 'redirect') {
					return
				}

				// Update the specified screen.
				if (screen) {
					const state = {}

					if (res instanceof Error) {
						state.error = res.message
					} else {
						Object.assign(state, res)
					}

					screen.setState(state)

					delete screen[flag]
				}

				if (typeof cb === 'function') {
					cb(res)
				} else if (res instanceof Error) {
					reject(res)
				} else {
					resolve(res)
				}
			}

			// Fetch data from the specified link.
			fetch(link)
				.then(res => {
					if (res.redirected) {
						redirect(res.url)
						throw new Error('redirect')
					}

					return res.json()
				})
				.then(finalize)
				.catch(finalize)

			// Set flag to avoid requesting a link twice.
			if (screen) {
				screen[flag] = true
			}
		}
	})
}

/**
 * Toggle processing state on a modal element.
 *
 * @param   object   modal  Modal DOM node.
 * @param   boolean  set    Whether set or unset processing state.
 *
 * @return  void
 */
export function toggleModalProcessingState(modal, set) {
	const closeBtn = modal.querySelector('.close')
	const okBtn = modal.querySelector('.btn-primary')
	const cancelBtn = modal.querySelector('.btn-secondary')

	closeBtn.disabled = set ? true : false
	cancelBtn.disabled = set ? true : false
	okBtn.origHTML = okBtn.origHTML || okBtn.innerHTML
	okBtn.innerHTML = set ? '<i class="fa fa-spin fa-spinner"/>' : okBtn.origHTML
}

/**
 * Helper function to get object value from the given key path, e.g. key1.key2.key3
 *
 * @param   object  obj   Object to get value for the given key path.
 * @param   string  path  Key path to get value for.
 *
 * @return  mixed
 */
export function getObjectValue(obj, path) {
	const keys = path.split('.')
	let value = obj

	for (let i = 0; i < keys.length; i++) {
		if (!value[keys[i]]) {
			return undefined
		}

		value = value[keys[i]]
	}

	return value
}
