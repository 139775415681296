/**
 * @version     $Id$
 * @package     PageFly_Affiliate_System
 * @subpackage  Client
 * @author      PageFly Team <admin@pagefly.io>
 * @copyright   Copyright (C) 2019 PageFly.io. All Rights Reserved.
 * @license     GNU/GPL v2 or later http://www.gnu.org/licenses/gpl-2.0.html
 */

// Import assets.
import './render.css'
import '../templates/track-referrer'

// Import necessary libraries.
import React from 'react'
import loadGA from '../components/ga'
import Header from '../components/header'
import Content from '../templates/content'
import {clearProfile, getProfile, UserContext} from './user'

/**
 * React component to render a requested screen.
 */
export default class Renderer extends React.Component {
	// Initial state.
	state = {
		user: {},
		screen: this.props.screen,
		renderers: {}
	}

	/**
	 * Get method to check whether current page requires login.
	 *
	 * @return  boolean
	 */
	get requireLogin() {
		return ['register', 'login', 'logout', 'forgot'].indexOf(this.state.screen) < 0
	}

	/**
	 * Handle changes in props.
	 *
	 * @param   object  props
	 * @param   object  state
	 *
	 * @return  object
	 */
	static getDerivedStateFromProps(props, state) {
		const newState = {}

		for (let p in props) {
			if (JSON.stringify(props[p]) !== JSON.stringify(state[p])) {
				newState[p] = props[p]
			}
		}

		if (JSON.stringify(newState) !== '{}') {
			return newState
		}

		return null
	}

	/**
	 * Method to refresh the profile of logged in user.
	 */
	refreshUser = () => {
		// Clear profile of logged in user stored in local storage.
		clearProfile()

		// Clear profile of logged in user stored in component state.
		if (this.state.user.email) {
			this.setState({user: {}})
		}
	}

	/**
	 * Method to get the profile of logged in user.
	 */
	async getUser() {
		const {user} = this.state

		if (this.requireLogin && !user.email) {
			try {
				const user = await getProfile()

				if (user) {
					this.setState({user})
				}
			} catch (e) {
				if (e.message !== 'login required') {
					this.setState({error: e.message})
				}
			}
		} else if (!this.requireLogin && user.email) {
			this.setState({user: {}})
		}
	}

	async init() {
		// Get user data.
		await this.getUser()

		if (this.state.screen === 'register') {
			loadGA('AW-994420181')
		}
	}

	async componentDidMount() {
		await this.init()
	}

	async componentDidUpdate() {
		await this.init()
	}

	render() {
		// Get screen renderer.
		const {server} = this.props
		const {user, screen, renderers} = this.state
		const Renderer = renderers[screen]

		// Dynamically load the requested renderer once.
		if (Renderer === undefined) {
			import('../screens/' + screen)
				.then(module => {
					// Store screen renderer.
					renderers[screen] = module.default || null

					// Then, update the renderer.
					this.setState({renderers})
				})
				.catch(error => {
					// Indicate that screen not found.
					renderers[screen] = error

					// Then, update the renderer.
					this.setState({renderers})
				})
		}

		// Start rendering.
		return (
			<div id="pagefly-affiliate-system">
				<UserContext.Provider value={{user, refresh: this.refreshUser}}>
					{!['login', 'register', 'forgot'].includes(screen) && <Header server={server} screen={screen}/>}
					<Content>
						{Renderer === undefined || (this.requireLogin && !user.email) ? (
							<div className="d-flex justify-content-center align-items-center overlay-backdrop">
								<i className="fa fa-2x fa-spin fa-spinner"/>
							</div>
						) : Renderer instanceof Error ? (
							<div className="alert alert-danger">
								{Renderer.toString()}
							</div>
						) : (
							<Renderer {...this.props}/>
						)}
					</Content>
				</UserContext.Provider>
			</div>
		)
	}
}
